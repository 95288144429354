import request from '@/utils/request'


// 查询订单取消原因列表
export function listCancelCause(query) {
  return request({
    url: '/order/cancel/cause/list',
    method: 'get',
    params: query
  })
}

// 查询订单取消原因分页
export function pageCancelCause(query) {
  return request({
    url: '/order/cancel/cause/page',
    method: 'get',
    params: query
  })
}

// 查询订单取消原因详细
export function getCancelCause(data) {
  return request({
    url: '/order/cancel/cause/detail',
    method: 'get',
    params: data
  })
}

// 新增订单取消原因
export function addCancelCause(data) {
  return request({
    url: '/order/cancel/cause/add',
    method: 'post',
    data: data
  })
}

// 修改订单取消原因
export function updateCancelCause(data) {
  return request({
    url: '/order/cancel/cause/edit',
    method: 'post',
    data: data
  })
}

export function statusCancelCause(data) {
  return request({
    url: '/order/cancel/cause/status',
    method: 'post',
    data: data
  })
}


// 删除订单取消原因
export function delCancelCause(data) {
  return request({
    url: '/order/cancel/cause/delete',
    method: 'post',
    data: data
  })
}
